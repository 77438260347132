
<template>
  <div class="staffList"
       id="print">
    <div v-for="(item, index) in listData"
         class="listItem"
         :key="index">
      <div class="content"
           @click="goDetail(item)"
           :style="{'cursor': buttonFlag ? 'pointer' : ''}">
        <!-- <span :class="item.grade == 1 ? 'type_o type_tag' : item.grade == 2 ? 'type_t type_tag' : item.grade == 3 || item.grade == 4 || item.grade == 5 || item.grade == 6 ? 'type_th type_tag' : ''">{{item.grade_cn}}</span> -->
        <div style="height: 55%; display: flex;border:2px solid #fff;border-bottom: none;" class="flag_top">
          <div class="left-head-img">
            <img :src="item.head_img ? `http://${item.head_img}` :headImg"
                alt />
          </div>
          <div class="right-content">
            <div class="right_text">
              <p style="font-size:14px;font-family:MicrosoftYaHei;line-height:19px;"><span style="font-family:MicrosoftYaHei;line-height:24px;margin-right: 10px;font-size:18px;">{{item.name}}</span></p>
              <span>{{item.sex == 1 ? '男' : '女'}}</span><span>{{item.birthday == 0 ? '' : item.birthday + '岁' || item.age}}</span>
            </div>
            <!-- <div class="right_1">
              <p v-for="(items, index) in contentText"
                v-if="item[items.field]"
                class="textHidden"
                :key="index">{{items.name + '：'}} {{item[items.field] ? item[items.field] : ''}}</p>
            </div> -->
          </div>
        </div>
        <div style="height: 45%;" class="bot_cen ">
          <p v-for="(items, index) in contentText"
                class="textHidden _gray"
                :key="index">{{items.name + '：'}} {{item[items.field] ? item[items.field] : ''}}

          </p>
           <p class="_omit _gray" v-if="item.station_name == '运动员'">教练： {{item.coach_name ? item.coach_name : ''}}</p>
          <p class="_omit _gray" v-if="item.station_name == '运动员'">项目： {{item.sport_name ? item.sport_name : ''}}</p>
        </div>
      </div>
      <div class="bottom" v-if="buttonFlag">
        <div class="button_item"
             @click="goDetail(item)"
             :key="index">
          查看 {{ "（" + item.disease_times  + "）"}}
        </div>
      </div>
    </div>
     <div class="staf" slot="empty" v-if="this.listData =='' ">
     {{Empty}}
    </div>
  </div>
</template>

<script>
import img from "../../../static/head-default-big.jpg"

export default {
  name: 'staffList',

  props: ['listData', 'contentText', 'buttonJson', 'buttonFlag'],

  data () {
    return {
      headImg: img,
      checkedArray: [],
      val: [],
        Empty: ' '
    }
  },

  methods: {
    goDetail (item) {
      // if (this.buttonFlag && !this.$store.getters.permissionsStr('查看')) {
        this.$emit('go-detail', item)
      // }
    },

    deleteClick (item) {
      if (!this.$store.getters.permissionsStr('删除')) {
        this.$emit('go-delete', item)
      }
    },

    goBackButton (item, res) {
      this.$emit('go-Back', item, res)
    },

    changeChe (item) {
      if (item.checked) {
        this.checkedArray.push(item.uuid)
        this.val.push(item)
      } else {
        let index = this.checkedArray.indexOf(item.uuid)
        this.checkedArray.splice(index, 1)
        this.val.splice(index, 1)
      }

      this.$emit('change-check', this.val, this.checkedArray)
    }
  },

  mounted () {
    // console.log(this.$store.getters.permissionsStr('查看'))
      setTimeout(() => {
      this.Empty = '暂无数据！'
    }, 2000)
  }
}
</script>

<style lang="scss" scoped>
.delete {
  position: absolute;
  bottom: 15px;
  right: 15px;
  cursor: pointer;
  font-size: 20px;
}

.hoverlj {
  color: #ccc;
}

.hoverlj:hover {
  color: #ff6666;
}

.staffList {
  width: 100%;
  min-height: 350px;
  // display: flex;
  // flex-wrap: wrap;
  overflow: hidden;
  padding-top: 10px;

  .listItem {
    width: 23.7%;
    height: 300px;
    box-shadow: 0px 2px 4px 3px rgba(3, 1, 1, 0.1);
    border: 2px solid rgba(231, 228, 228, 0.1);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    position: relative;
    float: left;
    overflow: hidden;
    box-sizing: border-box;
    margin: 0.5%;
    &:hover{
      // box-shadow: 0 10px 15px rgba(3, 27, 78, 0.06);
      border-color: #0055E9;
      cursor: pointer;
      .bottom{
        background: #0055E9;
        .button_item{
          color: #fff;
        }
      }
    }

    .grade {
      position: absolute;
      top: 35px;
      right: 10px;
      color: #fff;
      font-size: 12px;
      width: auto;
      min-width: 40px;
      padding: 2px 5px;
      line-height: 20px;
      border-radius: 20px;
      text-align: center;
    }

    .checked {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    .content {
      flex: 1;
      position: relative;

      .type_o {
        background: rgba($color: #F6C218, $alpha: 0.14);
        color: #F18033;
      }
      .type_t {
        background: rgba($color: #0055E9, $alpha: 0.14);
        color: #0055E9;
      }
      .type_th {
        background: rgba($color: #4DFF67, $alpha: 0.14);
        color: #18C431;
      }
          
      > span{
        position: absolute;
        top: 15px;
        right: 20px;
        padding: 5px 12px;
        font-size: 16px;
        font-weight: 400;
        border-radius: 20px;
      }
      .left-head-img {
        width: 90px;
        height: 90px;
        text-align: center;
        margin: 20px 30px;
        border-radius: 50%;
        overflow: hidden;
        > img {
          width: 100%;
          height: 100%;
        }
      }

      .bot_cen{
        background: rgba($color: #D8D8D8, $alpha: 0.2);
        padding-left: 25px;
        box-sizing: border-box;
        padding-top: 15px;
        .textHidden{
          color: #666;
        }
        p{
          padding-bottom: 10px;
        }
      }

      .right-content {
        flex: 1;
        margin-top: 25px;
        width: 30%;

        .right_text{
          p{
            margin-top: 8px;
            margin-bottom: 5px;
          }
          > span{
            margin-right: 10px;
            font-size: 15px;
            color: #666;
          }
        }
        
        .right_1 {
          margin-top: 20px;
          line-height: 30px;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: rgba(98, 98, 98, 1);
        }
      }
    }

    .bottom {
      width: 100%;
      height: 60px;
      line-height: 60px;
      background: #ccc;
      .button_item {
        text-align: center;
        font-size: 16px;
        color: #000;
        // font-weight: bold;
        cursor: pointer;
      }
    }
  }
}
._omit{
    color: #666;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
}
.staf{
   width: 100%;
  min-height: 550px;
  background-color: #fff;
  text-align: center;
  line-height: 550px;
  color: #6666;
}
</style>
